import React, { useContext } from 'react'
import Nav from './Home/nav'
import Footer from './Home/Footer'
import { AdminContext } from './Admin/Context/AdminContext'
import { Fade, Slide,Bounce,Hinge,Zoom} from "react-awesome-reveal";


const Gallery = () => {
  const {displayImage,displayError,displayLoading} =useContext(AdminContext)
  return (
    <div>
      <div className='gallery-nav'>
        <Nav/>
      </div>
      <div className='gallery-rest'>
        <Zoom>
        <div className='Gallery-header'>
            <h1>Gallery</h1>
            <p>Have a glimpse at our past endeavours.There are many memories to share but only a few can be captured, the rest you may find in the eyes of beneficiaries.</p>
        </div>`
        <Bounce>
        <div className='gallery-body'>
       { displayImage?.map((display,index)=>
        <div key={index} className='gallery-Image'>
          <img src={display.VWIBImage.secure_url} alt='gallery'/>
        </div>
        )}
        </div>
        </Bounce>`
        </Zoom>
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  )
}

export default Gallery
