import React from 'react'

const Conj = () => {
  return (
    <div className='conj'>
      <h2>in conjuction with</h2>
      <img src='/F1Yf2_Tz_400x400.jpg' alt='kn'/>
      <h3>Ministry of gender and social services Government of kenya</h3>
    </div>
  )
}

export default Conj
