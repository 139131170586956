import React from 'react'

const mission = () => {
  return (
    <div className='mission-vision'>
      <div className='mission'>
        <h2>Our Mission</h2>
        <p>“provision of education to women through mentorship entrepreneurship,leadership,decision making,capacity building and training."</p>
      </div>
      <div className='vision'>
        <h2>Our Vision</h2>
        <p>To have women in vihiga county empowered to enjoy dignified ,quality life and to understand their take in accessing and controlling productive resources.</p>
      </div>
    </div>
  )
}

export default mission
